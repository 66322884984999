import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { Col, Row, Form, Button, FormGroup, Label, Input, Spinner, Modal,
  ModalHeader,
  ModalBody } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { v4 as uuidv4 } from "uuid";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function PedidosCreate() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_PEDIDOS = process.env.REACT_APP_URL_PEDIDOS;
  const URL_ARTICULOS_PEDIDO = process.env.REACT_APP_URL_ARTICULOS_PEDIDO;
  const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;
  const URL_SOCIEDADES = process.env.REACT_APP_URL_SOCIEDADES;

  const [articulos, setArticulos] = useState([]);
  const [colores, setColores] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedColaborador, setSelectedColaborador] = useState("");
  const [value, setValue] = useState('');
  const [ordenesCompra, setOrdenesCompra] = useState([]);
  const [selectedOrdenesCompra, setSelectedOrdenesCompra] = useState("");
  const [sociedades, setSociedades] = useState([]);
  const [selectedSociedad, setSelectedSociedad] = useState("");
 
  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [fecha, setFecha] = useState("");
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState(0);
  const [total_kilos, setTotalKilos] = useState(0);
  const [total_metros, setTotalMetros] = useState(0);
  const [programacion, setProgramacion] = useState("");

  const classes = useStyles();
  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      articulos: "",
      unidad: "",
      colores: "",
      arrayColoresArticulo:[],
      cantidad: 0,
      precio: 0,
      descuento: 0,
      total: 0,
      clave:""
    },
  ]);

  const [validaBoton, setValidaBoton] = useState(true);

  useEffect(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allArticulos = res.data;
        setArticulos(allArticulos);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_SOCIEDADES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allSociedades = res.data;
        setSociedades(allSociedades);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColores = res.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(`${URL_CLIENTES}Activos`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(`${URL_ORDENES_COMPRA}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allOrdenes = response.data;
          setOrdenesCompra(allOrdenes);
    
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const savePedido = async (event) => {
    event.preventDefault();
    setValidaBoton(false);
    toggleProgreso();
    let totalPedido = inputFields.length

    try {
      if(programacion == "Si"){
        await axios
        .post(
          URL_PEDIDOS,
          {
            fecha,
            clientes:  value._id,
            colaboradores: selectedColaborador,
            fecha_compromiso,
            total_general,
            observaciones,
            total_kilos,
            total_metros,
            plazo: value.plazo,
            programacion,
            surtido: 0,
            pendiente_surtir: total_kilos + total_metros,
            ordenesCompra: selectedOrdenesCompra,
            sociedades: selectedSociedad,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if(a.articulos != "" && a.cantidad !=0){
            axios.post(
              URL_ARTICULOS_PEDIDO,
              {
                pedidos: data.data._id,
                articulos: a.articulos,
                colores: a.colores,
                cantidad: parseFloat(a.cantidad),
                surtido:0,
                pendiente_surtir: parseFloat(a.cantidad),
                precio: parseFloat(a.precio),
                descuento: parseFloat(a.descuento),
                total: parseFloat(a.total),
                clave: a.clave,
                ordenesCompra: selectedOrdenesCompra,
                sociedades: selectedSociedad,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(()=>{
              totalPedido = totalPedido -1
              if(totalPedido == 0 ){
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Crear Pedido",
                    detalle: `${ value._id} ${total_general}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                    },
                  }
                )
                  .then(()=>{
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
              }
            })
          }else{ 
            totalPedido = totalPedido -1
            if(totalPedido == 0 ){
              axios.post(
                URL_LOGS,
                {
                  tipo: "Crear Pedido",
                  detalle: `${value._id} ${total_general}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              )
                .then(()=>{
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
            }
          }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
      }else{
        await axios
        .post(
          URL_PEDIDOS,
          {
            fecha,
            clientes:  value._id,
            colaboradores: selectedColaborador,
            fecha_compromiso,
            total_general,
            observaciones,
            total_kilos,
            total_metros,
            plazo: value.plazo,
            programacion,
            surtido: 0,
            pendiente_surtir: total_kilos + total_metros,
            sociedades: selectedSociedad,
            ordenesCompra: selectedOrdenesCompra,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((data) => {
          inputFields.map((a) => {
            if(a.articulos != "" && a.cantidad !=0){
            axios.post(
              URL_ARTICULOS_PEDIDO,
              {
                pedidos: data.data._id,
                articulos: a.articulos,
                colores: a.colores,
                cantidad: parseFloat(a.cantidad),
                surtido:0,
                pendiente_surtir: parseFloat(a.cantidad),
                precio: parseFloat(a.precio),
                descuento: parseFloat(a.descuento),
                total: parseFloat(a.total),
                clave: a.clave,
                sociedades: selectedSociedad,
                ordenesCompra: selectedOrdenesCompra,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
            .then(()=>{
              totalPedido = totalPedido -1
              if(totalPedido == 0 ){
                axios.post(
                  URL_LOGS,
                  {
                    tipo: "Crear Pedido",
                    detalle: `${ value._id} ${total_general}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                    },
                  }
                )
                  .then(()=>{
                    Swal.fire("Good job!", "Creado con exito", `success`);
                    setTimeout(() => {
                      window.location.reload();
                    }, 1000);
                  })
              }
            })
          }else{ 
            totalPedido = totalPedido -1
            if(totalPedido == 0 ){
              axios.post(
                URL_LOGS,
                {
                  tipo: "Crear Pedido",
                  detalle: `${value._id} ${total_general}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                  },
                }
              )
                .then(()=>{
                  Swal.fire("Good job!", "Creado con exito", `success`);
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
            }
          }
          });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
          setValidaBoton(true);
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  };

  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length-1];
    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        articulos: ultimo.articulos,
        unidad: ultimo.unidad,
        colores: "",
        arrayColoresArticulo:ultimo.arrayColoresArticulo,
        cantidad: 0,
        precio: ultimo.precio,
        descuento: ultimo.descuento,
        total: 0,
        clave:""
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mts = values.filter(e=>e.unidad == "Metros")
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = values.filter(e=>e.unidad == "Kilos")
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);
    let importes = values.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneral(TG);
  };

  const handleChangeInput = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(2);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };

  function BuscaArticulo(id, event){
    articulos.map((a)=>{
      if(a._id == event.target.value){
        let idArticulo = a._id
        let unidadArticulo = a.unidad
        let coloresArticulo = a.colores
        handleChangeInputArticulo(id, idArticulo, unidadArticulo, coloresArticulo)
      }
    })
  }

  const handleChangeInputArticulo = (id, idArticulo, unidadArticulo, coloresArticulo) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.articulos = idArticulo;
        i.unidad = unidadArticulo
        i.arrayColoresArticulo = coloresArticulo
        let totalParcial = i.cantidad * i.precio;
        i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(2);
      }
      return i;
    });
    setInputFields(newInputFields);
    ActualizaTotales()
  };


  function ActualizaTotales(){
        let mts = inputFields.filter(e=>e.unidad == "Metros")
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetros(TM);
        let KG = inputFields.filter(e=>e.unidad == "Kilos")
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilos(TK);
        let importes = inputFields.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneral(TG);
  }

  const options = clientes.map((option) => {
    const junta = option.nombre_comercial
    const firstLetter = option.nombre_comercial[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option, junta
    };
  })

  return (
    <>
      <Header />
      <br />
      <br />
      {user.pedidos_create ? (
        <div className="card container col-12">
          <h3 align="center">Nuevo Pedido</h3>
          <Form onSubmit={savePedido}>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  required
                  onChange={(e) => {
                    setFecha(e.target.value);
                  }}
                />
              </Col>
              <Col md={3}>
                  <Label className="mr-sm-2">Cliente</Label>
                  <Autocomplete
                    size="small"
                    value={value}
                    onChange={(event, selectedCliente) => {
                      setValue(selectedCliente);
                    }}
                    options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.junta}
                    renderInput={(params) => <TextField {...params} label="Selecciona" variant="outlined" />}
                    renderOption={(option) => (
                      <React.Fragment>
                    {option.codigo} {option.nombre_comercial}
                      </React.Fragment>
                    )}
                  />
                </Col>
              {/* <Col md={3}>
                <Label className="mr-sm-2">Cliente</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedCliente}
                  required
                  onChange={(e) => {
                    setSelectedCliente(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Cliente</option>
                  {clientes
                    .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre_comercial}</option>;
                    })}
                </Input>
              </Col> */}
              <Col md={3}>
              <Label>Vendedor</Label>
              <Input
                type="select"
                value={selectedColaborador}
                onChange={(e) => {
                  setSelectedColaborador(e.target.value)
                }}
              >
                <option value="">Selecciona un Vendedor</option>
                {colaboradores
                  .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                  .map((a) => {
                    // if(a.puestos[0].name == "Ventas"){
                      return <option value={a._id}>{a.nombre} {a.apellido}</option>;
                    // }
                    
                  })}
              </Input>
            </Col>
              <Col md={3}>
                <Label>Fecha Compromiso</Label>
                <Input
                  type="date"
                  placeholder="Fecha Compromiso"
                  value={fecha_compromiso}
                  required
                  onChange={(e) => {
                    setFechaCompromiso(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
            <Col md={3}>
                <Label className="mr-sm-2">Sociedad</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedSociedad}
                  required
                  onChange={(e) => {
                    setSelectedSociedad(e.target.value);
                  }}
                >
                  <option value="0">Selecciona un Sociedad</option>
                  {sociedades
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre}</option>;
                    })}
                </Input>
              </Col>
              <Col md={4}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="text"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
              <Col md={4}>
                <Row>
              <Col md={6}>
              <Label>Programacion</Label>
              <Input
                // bsSize="sm"
                type="select"
                value={programacion}
                required
                onChange={(e) => {
                  setProgramacion(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </Input>
            </Col>
              {/* <br /> */}
              {/* {programacion == "Si"?(
                <> */}
                <Col md={6}>
                  <Label>Orden de Compra</Label>
                  <Input
                    // bsSize="sm"
                    type="select"
                    value={selectedOrdenesCompra}
                    required
                    onChange={(e) => {
                      setSelectedOrdenesCompra(e.target.value);
                    }}
                  >
                    <option value="">Selecciona</option>
                    {ordenesCompra
                  .sort((a, b) => (a.idOrdenCompra < b.idOrdenCompra ? 1 : -1))
                  .map((a)=>{
                      if(a.is_active == "Si" && a.status == "Abierto"){
                      return <option value={a._id}>{a.idOrdenCompra} {a.observaciones}</option>
                    }
                    })}
                  </Input>
            </Col>
                  {/* </>
              ):undefined} */}
              </Row>
            </Col>
              {/* <Col md={2}>
              <h4 id="logoutBoton">
              TOTALES {total_kilos} kg. / {total_metros} mts. / {"$" + new Intl.NumberFormat("en-US").format(total_general)}
              </h4>
              </Col> */}
            </Row>

            {/* Tabla Articulos */}

            {/* <Form className={classes.root}> */}
              <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Articulos</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Unidad</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Colores</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Clave</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Precio</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Descuento</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Total</Label>
                </Col>
              </Row>

              {inputFields.map((inputField) => (
                <div key={inputField.id}>
                  <Row>
                    <Col md={2}>
                      <Input
                        bsSize="sm"
                        name="articulos"
                        type="select"
                        value={inputField.articulos}
                        required
                        onChange={(event)=>{BuscaArticulo(inputField.id, event)}}
                      >
                        <option value="">Selecciona un Articulo</option>
                        {articulos
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.nombre} / {a.codigo}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={1}>
                        <Input
                         bsSize="sm"
                          name="unidad"
                          type="string"
                          value={inputField.unidad}
                          disabled
                        >
                        </Input>
                      </Col>
                    <Col md={2}>
                      <Input
                        bsSize="sm"
                        name="colores"
                        type="select"
                        value={inputField.colores}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      >
                        <option value="">Selecciona un Color</option>
                        {inputField.arrayColoresArticulo
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                    </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="clave"
                        type="text"
                        placeholder="Clave"
                        value={inputField.clave}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="precio"
                        type="number"
                        placeholder="Precio"
                        value={inputField.precio}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="descuento"
                        type="number"
                        placeholder="Descuento"
                        value={inputField.descuento}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        bsSize="sm"
                        name="total"
                        type="number"
                        placeholder="Total"
                        value={inputField.total}
                        disabled
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                    <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(inputField.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button 
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1">
                      <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
              <Row>
              <Col md={10}>
              <h4 id="logoutBoton">
              TOTALES {total_kilos} kg. / {total_metros} mts. / {"$" + new Intl.NumberFormat("en-US").format(total_general)}
              </h4>
              </Col>
              </Row>
            {/* </Form> */}
            {/* Termina Tabla Articulos */}

            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
            <Button
              href="/ListadoPedidos"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
          </Form>
          <br />
        <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : undefined}
    </>
  );
}

export default PedidosCreate;
